@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

.ant-spin-dot {
    font-size: 150px !important;
    color: #002e8c !important;
}

.ellipses {
    font-size: 30px;
    width: 15px;
}

.ellipses:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

.modal {
    z-index: 110 !important;
}

.slug-txt-input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #009ee4;
    border-radius: 80px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    padding: 15px 2rem;
}
.slug-val {
    text-align: left;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 600;
    font: normal normal medium 32px/90px Poppins;
    letter-spacing: 0px;
    color: #002e8c;
    opacity: 1;
}
.slug-check {
    margin-top: auto;
    margin-bottom: auto;
}

label {
    font-family: Poppins !important;
}
.MuiLinearProgress-barColorPrimary {
    background-color: #ae72e1 !important;
}
.warn-line {
    color: #de3c3c;
    font-weight: 500;
    text-align: end;
}
.cntner {
    margin-top: 60px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.ant-input-number-handler-wrap {
    display: none !important;
}
.maintenance {
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    font-size: 22px;
    font-weight: 500;
}
.sorry {
    color: #691aaa;
    font-weight: 500;
    font-size: 28px;
}

.MuiAlert-standardError {
    color: #ededed !important;
    background-color: rgb(201 0 1) !important;
}
.MuiSnackbar-anchorOriginTopCenter {
    z-index: 2000 !important;
}
.MuiAlert-standardError .MuiAlert-icon {
    color: #ededed !important;
}
