.modal-content,
.modal-card {
    /* background: linear-gradient(126.9deg, #00386c 28.56%, #00659d 97.06%); */
    border-radius: 26.6px;
}
.modal.is-active {
    height: 100vh;
  overflow-y: hidden;
    z-index: 1800 !important;
    position: fixed;
}
