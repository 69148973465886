.modal-content,
.modal-card {
    // background: linear-gradient(126.9deg, #00386c 28.56%, #00659d 97.06%);
    background-color: #272750;
    border-radius: 10px;
    width: fit-content;
}
.modal.is-active {
    z-index: 1800 !important;
}

.MuiDialog-paper {

    border: 1px solid #1484C3 !important;
    background-color: #171A3C !important;
    width: 875px !important;
    height: 358px !important;
@media screen and (max-width: 600px) {
    
    border-radius: 11px !important;
    width: 300px !important;
    height: 365px !important;
  }
}




