.load-9 .spinner {
    animation: loadingI 2s linear infinite;
}
.load-9 .bubble-1,
.load-9 .bubble-2 {
    animation: bounce 2s ease-in-out infinite;
}
.load-9 .bubble-2 {
    animation-delay: -1s;
}

@keyframes loadingI {
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    position: relative;
    width: 60px;
    height: 70px;
    margin: 0 auto;
}

.bubble-1,
.bubble-2 {
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #c90001;
}

.bubble-2 {
    top: auto;
    bottom: 0;
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
